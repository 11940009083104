import { createAction, props } from '@ngrx/store';

import { QrCodeScannerProperties } from './qr-code-scanner.model';

export const initScanner = createAction('[QrCodeScanner Page] Initialize scanner');
export const startScanner = createAction('[QrCodeScanner Page] Start scanner', props<QrCodeScannerProperties>());
export const stopScanner = createAction('[QrCodeScanner Page] Stop scanner');

export const scan = createAction('[QrCodeScanner/API] Scan ...', props<QrCodeScannerProperties>());
export const detectedText = createAction('[QrCodeScanner/API] Scan successful', props<{ scannedText: string }>());
export const changePermission = createAction(
  '[QrCodeScanner/API] Change permissions',
  props<{ permission: PermissionState }>()
);
export const stop = createAction('[QrCodeScanner/API] Stop scanning');
