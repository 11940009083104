import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { selectValidatorResult } from './qr-code-validator.selectors';

@Injectable()
export class QRCodeValidatorFacade {
  private store = inject(Store);

  validatorResult$ = this.store.pipe(select(selectValidatorResult));
}
