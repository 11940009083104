import { Injectable } from '@angular/core';
import { Html5Qrcode } from 'html5-qrcode';
import { from, map, Observable } from 'rxjs';

import { QrCodeScannerProperties } from '../../state/qr-code-scanner/qr-code-scanner.model';

@Injectable()
export class QrCodeScannerService {
  private html5QrCode: Html5Qrcode | undefined;

  queryPermissions(): Observable<PermissionState> {
    // * for unknown reason the query does not recognize
    // * the descriptor `camera` whilst the
    // * "Media Capture and Streams API", consisting of
    // * `microphone` and `camera` is clearly stated
    // * as an permission-aware api.
    // * see: https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API#permission-aware_apis
    // @ts-expect-error see above explanation
    return from(navigator.permissions.query({ name: 'camera' })).pipe(map((status) => status.state));
  }

  startScanning({
    elementId,
    constraints = { facingMode: 'environment' },
    config = { fps: 10, qrbox: { width: 230, height: 230 } },
  }: QrCodeScannerProperties): Observable<string> {
    this.html5QrCode = new Html5Qrcode(elementId);
    return new Observable<string>((subscriber) => {
      const qrCodeSuccessCallback = (decodedText: string) => subscriber.next(decodedText);
      // * we really want to disable eslint for the next line
      // * as this function handles the errors thrown by every
      // * single frame that contains no readable qr-code
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      const qrCodeErrorCallback = () => {};
      this.html5QrCode
        ?.start(constraints, config, qrCodeSuccessCallback, qrCodeErrorCallback)
        .catch(() => subscriber.error());
    });
  }

  stopScanning(): void {
    this.html5QrCode?.stop();
    this.html5QrCode = undefined;
  }
}
