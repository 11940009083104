import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { select, Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs';

import {
  changePermission,
  detectedText,
  initScanner,
  scan,
  startScanner,
  stop,
  stopScanner,
} from './qr-code-scanner.actions';

import { selectPermission } from './qr-code-scanner.selectors';

import { QrCodeScannerService } from '../../service/qr-code-scanner/qr-code-scanner.service';

@Injectable()
export class QrCodeScannerEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private service = inject(QrCodeScannerService);

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initScanner),
      switchMap(() => this.service.queryPermissions().pipe(map((permission) => changePermission({ permission }))))
    )
  );

  startScanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startScanner),
      map((props) => scan(props))
    )
  );

  stopScanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stopScanner),
      map(() => stop())
    )
  );

  scan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(scan),
      switchMap((props) =>
        this.service.startScanning(props).pipe(
          map((scannedText) => detectedText({ scannedText })),
          catchError(() => of(changePermission({ permission: 'denied' })))
        )
      )
    )
  );

  successfulScan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(detectedText),
      withLatestFrom(this.store.pipe(select(selectPermission))),
      filter(([_, permission]) => permission !== 'granted'),
      map(() => changePermission({ permission: 'granted' }))
    )
  );

  changePermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changePermission),
      filter(({ permission }) => permission === 'denied'),
      map(() => stop())
    )
  );

  performStop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stopScanner),
      map(() => stop())
    )
  );

  stop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stop),
        tap(() => this.service.stopScanning())
      ),
    { dispatch: false }
  );
}
