import { Action, createReducer, on } from '@ngrx/store';

import { lockValidator, unlockValidator, validateScannedText, validationFinished } from './qr-code-validator.actions';
import { ValidatorResult } from './qr-code-validator.model';

export const QR_CODE_VALIDATOR_FEATURE_KEY = 'qrCodeValidator';

export interface QrCodeValidatorState {
  isLocked?: boolean;
  validatorResult?: ValidatorResult;
}

export interface QrCodeValidatorPartialState {
  readonly [QR_CODE_VALIDATOR_FEATURE_KEY]: QrCodeValidatorState;
}

export const initialQrCodeValidatorState: QrCodeValidatorState = {};

const reducer = createReducer(
  initialQrCodeValidatorState,
  on(lockValidator, (state) => ({ ...state, isLocked: true })),
  on(unlockValidator, (state) => ({ ...state, isLocked: false })),
  on(validateScannedText, (state) => ({ ...state, validatorResult: undefined })),
  on(validationFinished, (state, { validatorResult }) => ({ ...state, validatorResult }))
);

export function qrCodeValidatorReducer(state: QrCodeValidatorState | undefined, action: Action) {
  return reducer(state, action);
}
