import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { NotificationService } from '@planone/bau365/util-notification';
import { detectedText, stop } from '@planone/shared/data-access-qrcode-scanner';
import { filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs';

import { lockValidator, unlockValidator, validateScannedText, validationFinished } from './qr-code-validator.actions';

import { CodeType } from './qr-code-validator.model';

import { isLocked } from './qr-code-validator.selectors';

import { QrCodeValidatorService } from '../../services/qr-code-validator.service';

@Injectable()
export class QrCodeValidatorEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private validator = inject(QrCodeValidatorService);
  private router = inject(Router);
  private notificationService = inject(NotificationService);

  detectedText$ = createEffect(() =>
    this.actions$.pipe(
      ofType(detectedText),
      withLatestFrom(this.store.pipe(select(isLocked))),
      filter(([_, isLocked]) => !isLocked),
      map(([{ scannedText }]) => validateScannedText({ scannedText }))
    )
  );

  validateText$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateScannedText),
      switchMap(({ scannedText }) =>
        of(validationFinished({ validatorResult: this.validator.validateScannedText(scannedText) }))
      )
    )
  );

  stopScanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validationFinished),
      filter(({ validatorResult }) => validatorResult.codeType !== CodeType.UNKNOWN),
      map(() => stop())
    )
  );

  businessCardValidationSuccessful$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(validationFinished),
        tap(({ validatorResult }) => {
          if (validatorResult.codeType === CodeType.UNKNOWN) {
            // this.notificationService.projectContent(
            //   $localize`:@@featureExport-toasts-downloadFailed:Herunterladen fehlgeschlagen`,
            //   SnackBarType.failure,
            //   undefined,
            //   true
            // );
          } else {
            window.open(
              this.router.serializeUrl(
                this.router.createUrlTree(['business-card', validatorResult.uuid], {
                  queryParams: { codeType: validatorResult.codeType, id: validatorResult.uuid },
                })
              ),
              '_self'
            );
          }
        })
      ),
    { dispatch: false }
  );

  lockValidator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateScannedText),
      withLatestFrom(this.store.pipe(select(isLocked))),
      filter(([_, isLocked]) => !isLocked),
      map(() => lockValidator())
    )
  );

  unlockValidator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validationFinished),
      map(() => unlockValidator())
    )
  );
}
