import { Action, createReducer, on } from '@ngrx/store';

import { changePermission, detectedText, scan, stop } from './qr-code-scanner.actions';

export const QR_CODE_SCANNER_FEATURE_KEY = 'qrCodeScanner';

export interface QrCodeScannerState {
  isScanning: boolean;
  scannedText?: string;
  permission?: PermissionState;
}

export interface QrCodeScannerPartialState {
  readonly [QR_CODE_SCANNER_FEATURE_KEY]: QrCodeScannerState;
}

export const initialQrCodeScannerState: QrCodeScannerState = { isScanning: false };

const reducer = createReducer(
  initialQrCodeScannerState,
  on(changePermission, (state, { permission }) => ({ ...state, permission })),
  on(scan, (state) => ({ ...state, isScanning: true, scannedText: undefined })),
  on(stop, (state) => ({ ...state, isScanning: false })),
  on(detectedText, (state, { scannedText }) => ({ ...state, scannedText }))
);

export function qrCodeScannerReducer(state: QrCodeScannerState | undefined, action: Action) {
  return reducer(state, action);
}
