import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { QrCodeScannerService } from './service/qr-code-scanner/qr-code-scanner.service';
import { QrCodeScannerEffects } from './state/qr-code-scanner/qr-code-scanner.effects';
import { QrCodeScannerFacade } from './state/qr-code-scanner/qr-code-scanner.facade';
import * as fromQrCodeScanner from './state/qr-code-scanner/qr-code-scanner.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromQrCodeScanner.QR_CODE_SCANNER_FEATURE_KEY, fromQrCodeScanner.qrCodeScannerReducer),
    EffectsModule.forFeature([QrCodeScannerEffects]),
  ],
  providers: [QrCodeScannerFacade, QrCodeScannerService],
})
export class SharedDataAccessQrcodeScannerModule {}
