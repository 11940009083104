import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import {
  checkBadgeCode,
  extractBusinessCardIdFromUrl,
  extractTicketCodeFromUrl,
  isBadgeId,
  isUrlWithGivenHost,
  validationResult,
} from './qr-code-validator.utils';

import { QR_CODE_VALIDATOR_CONFIG } from '../config/qr-code-validator.config.interface';

import { CodeType, ValidatorResult } from '../state/qr-code-validator/qr-code-validator.model';

@Injectable()
export class QrCodeValidatorService {
  private http = inject(HttpClient);
  private config = inject(QR_CODE_VALIDATOR_CONFIG);

  validateScannedText(text: string): ValidatorResult {
    if (isUrlWithGivenHost(text, [this.config.host])) {
      const uuid: string | undefined = extractBusinessCardIdFromUrl(text);
      if (!uuid) return validationResult(CodeType.UNKNOWN, text);
      return validationResult(CodeType.BUSINESS_CARD, uuid);
    } else if (isUrlWithGivenHost(text, ['http://quico.de'])) {
      const ticketCode = extractTicketCodeFromUrl(text);
      if (!ticketCode) return validationResult(CodeType.UNKNOWN, text);
      return validationResult(CodeType.TICKET, ticketCode);
    } else if (isBadgeId(text)) {
      /* aktuell recht simple Prüfung der BadgeId */
      /* evtl. noch erweiterte Prüfung des BadgeCodes */
      const badgeCode = checkBadgeCode(text);
      if (!badgeCode) return validationResult(CodeType.UNKNOWN, text);
      return validationResult(CodeType.BADGE, badgeCode);
    } else {
      return validationResult(CodeType.UNKNOWN, text);
    }
  }
}
