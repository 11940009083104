import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedDataAccessQrcodeScannerModule } from '@planone/shared/data-access-qrcode-scanner';

import { QrCodeValidatorService } from './services/qr-code-validator.service';
import { QrCodeValidatorEffects } from './state/qr-code-validator/qr-code-validator.effects';
import { QRCodeValidatorFacade } from './state/qr-code-validator/qr-code-validator.facade';
import * as fromQrCodeValidator from './state/qr-code-validator/qr-code-validator.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromQrCodeValidator.QR_CODE_VALIDATOR_FEATURE_KEY,
      fromQrCodeValidator.qrCodeValidatorReducer
    ),
    EffectsModule.forFeature([QrCodeValidatorEffects]),
    SharedDataAccessQrcodeScannerModule,
  ],
  providers: [QRCodeValidatorFacade, QrCodeValidatorService],
})
export class Bau365DataAccessQrcodeScannerModule {}
