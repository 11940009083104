import { CodeType, ValidatorResult } from '../state/qr-code-validator/qr-code-validator.model';

export function validationResult(codeType: CodeType, uuid: string): ValidatorResult {
  return { codeType, uuid };
}

export function isUrlWithGivenHost(url: string, validHosts: string[]) {
  return validHosts.map((host) => host.toLocaleLowerCase()).some((host) => url.toLocaleLowerCase().startsWith(host));
}

export function extractBusinessCardIdFromUrl(url: string): string | undefined {
  const startIndex = url.indexOf('/business-card/');
  if (startIndex !== -1) {
    const idStartIndex = startIndex + '/business-card/'.length;
    const idPart = url.substring(idStartIndex);
    const idEndIndex =
      idPart.indexOf('?') !== -1
        ? idPart.indexOf('?')
        : idPart.indexOf('#') !== -1
        ? idPart.indexOf('#')
        : idPart.length;
    return idPart.substring(0, idEndIndex);
  } else {
    throw new Error('Invalid URL');
  }
}

export function extractTicketCodeFromUrl(url: string): string | undefined {
  return url.slice(16, 30);
}

export function isBadgeId(id: string): boolean {
  return id.length === 16;
}

export function checkBadgeCode(code: string): string | undefined {
  return !isNaN(parseFloat(code)) ? code : undefined;
}
