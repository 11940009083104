import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { DataAccessTogglesFacade } from '@planone/shared/data-access-feature-flags';
import { map } from 'rxjs';

export const QrCodeScannerEnabledGuard = () => {
  const router = inject(Router);
  return inject(DataAccessTogglesFacade)
    .flag('kill-qrcode-scanner')
    .pipe(map((qrCodeScannerDisabled) => (qrCodeScannerDisabled ? router.navigate(['/']) : true)));
};
