import { createFeatureSelector, createSelector } from '@ngrx/store';

import { QR_CODE_SCANNER_FEATURE_KEY, QrCodeScannerState } from './qr-code-scanner.reducer';

export const selectQrCodeScannerState = createFeatureSelector<QrCodeScannerState>(QR_CODE_SCANNER_FEATURE_KEY);

export const selectIsScanning = createSelector(
  selectQrCodeScannerState,
  (state: QrCodeScannerState) => state.isScanning
);

export const selectScannedText = createSelector(
  selectQrCodeScannerState,
  (state: QrCodeScannerState) => state.scannedText
);

export const selectPermission = createSelector(
  selectQrCodeScannerState,
  (state: QrCodeScannerState) => state.permission
);
