import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { initScanner, startScanner, stop } from './qr-code-scanner.actions';
import { QrCodeScannerProperties } from './qr-code-scanner.model';
import { selectIsScanning, selectPermission, selectScannedText } from './qr-code-scanner.selectors';

@Injectable()
export class QrCodeScannerFacade {
  private readonly store = inject(Store);

  isScanning$ = this.store.pipe(select(selectIsScanning));
  scannedText$ = this.store.pipe(select(selectScannedText));
  permission$ = this.store.pipe(select(selectPermission));

  initScanner(): void {
    this.store.dispatch(initScanner());
  }

  startScanning(properties: QrCodeScannerProperties) {
    this.store.dispatch(startScanner(properties));
  }

  stopScanning() {
    this.store.dispatch(stop());
  }
}
