import { createFeatureSelector, createSelector } from '@ngrx/store';

import { QR_CODE_VALIDATOR_FEATURE_KEY, QrCodeValidatorState } from './qr-code-validator.reducer';

export const selectQrCodeValidatorState = createFeatureSelector<QrCodeValidatorState>(QR_CODE_VALIDATOR_FEATURE_KEY);

export const selectValidatorResult = createSelector(
  selectQrCodeValidatorState,
  (state: QrCodeValidatorState) => state.validatorResult
);

export const isLocked = createSelector(selectQrCodeValidatorState, (state: QrCodeValidatorState) => state.isLocked);
