export enum CodeType {
  BUSINESS_CARD = 'BUSINESS_CARD',
  TICKET = 'TICKET',
  BADGE = 'BADGE',
  UNKNOWN = 'UNKNOWN',
}

export interface ValidatorResult {
  codeType: CodeType;
  uuid: string;
}
