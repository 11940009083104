import { createAction, props } from '@ngrx/store';

import { ValidatorResult } from './qr-code-validator.model';

export const lockValidator = createAction('[QrCodeValidator/API] Lock validator for further calls');
export const unlockValidator = createAction('[QrCodeValidator/API] Unlock validator for further calls');

export const validateScannedText = createAction(
  '[QrCodeValidator/API] Validate scanned text',
  props<{ scannedText: string }>()
);

export const validationFinished = createAction(
  '[QrCodeValidator/API] Scanned text validated successful',
  props<{ validatorResult: ValidatorResult }>()
);

export const validationFailed = createAction('[QrCodeValidator/API] Scanned text validation failed');
